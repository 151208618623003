<template>
    <div class="loginPageBox">
        <div id="loginPage">
        <div class="img-c">
          <img src="@/assets/img/login/lgbg3.png" alt="">
        </div>
        <div class="fr login_box">
          <div class="projectName">
            智能建造数字教育平台注册
          </div>
          <div class="lp-cont">
              
            <el-form class="loginForm" ref="ruleFormRef" :model="loginForm"  :rules="rules" :label-position="labelPosition" label-width="130px">
               <el-form-item prop="registerType" label="选择身份:">
                <el-select style="width:100%" v-model="loginForm.registerType" class="m-2"  placeholder="请选择注册身份"  @change="registerTypeChange">
                          <el-option
                          v-for="item in registerTypeOption"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          />
              </el-select>
              </el-form-item>
              <el-form-item prop="username" label="姓名:">
              <el-input
                v-model="loginForm.username"
                placeholder="请输入姓名"
                maxlength="20"
                show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item prop="gender" label="性别:">
                <el-select style="width:100%" v-model="loginForm.gender" class="m-2"  placeholder="请选择性别">
                          <el-option
                          v-for="item in genderOption"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          />
              </el-select>
              </el-form-item>
              <el-form-item prop="studentID" v-if="loginForm.registerType == 2" label="学号:">
              <el-input
                v-model="loginForm.studentID"
                placeholder="请输入学号"
                show-word-limit>
              </el-input>
            </el-form-item>
              <el-form-item prop="QQNum" label="QQ号:">
              <el-input
                v-model="loginForm.QQNum"
                placeholder="请输入QQ号"
                maxlength="14"
                show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item prop="email" label="邮箱:">
              <el-input
                v-model="loginForm.email"
                placeholder="请输入邮箱"
                maxlength="40"
                show-word-limit>
              </el-input>
            </el-form-item>
              <el-form-item prop="educational" label="学校:">
              <el-select style="width:100%" v-model="loginForm.educational" class="m-2"  placeholder="请选择学校"  @change="selectChange">
                          <el-option
                          v-for="item in educationalOption"
                          :key="item.id"
                          :label="item.schoolName"
                          :value="item.id"
                          />
              </el-select>
              </el-form-item>
              <el-form-item prop="system" label="院系:">
              <el-select style="width:100%" v-model="loginForm.system" class="m-2"  placeholder="请选择院系"  @change="systemChange">
                          <el-option
                          v-for="item in systemOption"
                          :key="item.id"
                          :label="item.departmentName"
                          :value="item.id"
                          />
              </el-select>
              </el-form-item>
              <el-form-item prop="major" :label="loginForm.registerType == 2?'专业:':'专业:'">
              <el-select style="width:100%" v-model="loginForm.major" class="m-2"  placeholder="请选择专业"  @change="majorChange">
                          <el-option
                          v-for="item in majorOptions"
                          :key="item.id"
                          :label="item.majorName"
                          :value="item.id"
                          />
              </el-select>
              </el-form-item>
              <el-form-item prop="juese" label="角色:" v-if="loginForm.registerType == 1">
                <el-select style="width:100%" v-model="loginForm.juese" class="m-2"  placeholder="请选择角色">
                            <el-option
                            v-for="item in jueseOptions"
                            :key="item.id"
                            :label="item.roleName"
                            :value="item.id"
                            />
                </el-select>
              </el-form-item>
              <!-- <el-form-item prop="subject" v-if="loginForm.registerType == 1" label="所教科目:">
              <el-select style="width:100%" v-model="loginForm.subject" class="m-2"  placeholder="请选择科目"  @change="subjectChange">
                          <el-option
                          v-for="item in subjectOptions"
                          :key="item.id"
                          :label="item.subjectName"
                          :value="item.id"
                          />
              </el-select>
              </el-form-item> -->
              <el-form-item prop="class" v-if="loginForm.registerType == 2" label="所在班级:">
              <el-select style="width:100%" v-model="loginForm.class" class="m-2" placeholder="请选择班级">
                          <el-option
                          v-for="item in classOptions"
                          :key="item.id"
                          :label="item.className"
                          :value="item.id"
                          />
              </el-select>
              </el-form-item>
              <el-form-item prop="cellPhone" label="手机号:">
                <el-input
                  v-model="loginForm.cellPhone"
                  placeholder="请输入手机号"
                  maxlength="11"
                  show-word-limit>
                </el-input>
              </el-form-item>
              <el-form-item class="codeItem lg-from" prop="isPass" label="滑动验证:" label-width="250px">
                <drag-verify
                  ref="dragVerify"
                  v-model:isPassing.sync="loginForm.isPass"
                  background = "#fff"
                  completedBg="#76c8f6"
                  progressBarBg="#76c8f6"
                  text="滑动验证"
                  successText="验证通过"
                  handlerIcon="el-icon-d-arrow-right"
                  successIcon="el-icon-check"
                  @passcallback="passcallback"
                  :width="width"
                  :height="height"
                  :circle="circle"
                  textSize="14px"
                  textColor="#666"
                ></drag-verify>
              </el-form-item>
               <el-form-item prop="verificationCode" label="短信验证码:">
                <el-input
                  v-model="loginForm.verificationCode"
                  placeholder="请输入验证码">
                </el-input>
                <div class="el-button" v-if="bVerification">{{ countDown }}</div>
                <div class="el-button" @click="sendCode" v-else>{{ countDown }}</div>    
              </el-form-item>
              <el-form-item prop="password" label="请设置密码:">
                <el-input
                type="password"
                show-password
                  v-model="loginForm.password"
                  placeholder="请输入密码">
                </el-input> 
              </el-form-item>
              <el-form-item prop="againPassword" label="请再次输入密码:">
                <el-input
                 type="password"
                 show-password
                  v-model="loginForm.againPassword"
                  placeholder="请再次输入密码">
                </el-input> 
              </el-form-item>
              <el-form-item class="lg-btn">
                <button
                  type="button"
                  class="loginBtn"
                  @click="sibmitLogin"
                >注册并提交审核</button>
              </el-form-item>
              <p style="float:right;margin-top:-10px;color:#76C8F6;font-size:14px;cursor:pointer" @click="gologin">已有账号,去登录</p>
            </el-form>
          </div>
        </div>
    </div>

    <el-dialog v-model="centerDialogVisible" title="" width="20%" center :show-close="false" :modal="false">
      <div style="text-align: center;font-size: 20px;font-weight: bold;color: #000;">提交成功</div>
      <p style="text-align: center;">账号审核通过后，将以短信的形式通知</p>
      <template #footer>
        <span style="color: #999999;">
            {{ countdownSeconds }}秒后将自动返回登录页面
        </span>
      </template>
    </el-dialog>

    </div>
 
  </template> 
  <script>
  import {
    reactive,
    toRefs,
    onMounted,
    ref
  } from "vue";
  import dragVerify from "@/components/commons/dragVerify";
  import { useRouter } from "vue-router";
  import { setStorage } from "@/js/common";
  import { defineComponent ,computed } from "vue";
  import {SendVerificationCode,F_pulldown_department,F_pulldown_majorForDepartmentIdList,F_pulldown_classForMajorIdList,F_Register_studentUser,F_Register_teacherUser,get_select_classNumList} from '@/js/loginApi'
  import {get_select_schoolList,get_select_departmentList,get_select_majorList,get_select_role} from '@/js/teachingSquare'
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";
  export default defineComponent({
    setup() {
      const router = useRouter();
      const store = useStore();
      const ruleFormRef = ref(null)
      const validatePass = (rule, value, callback) => {
      if (!value) {
          callback(new Error('请先完成滑动认证'))
      } else {
      callback()
    }
    callback()
  }
  const password = (rule, value, callback) => {
       if (value != state.loginForm.password) {
          callback(new Error('两次输入的密码不一致'))
      } else {
      callback()
    }
  }
  
   const validatePhone = (rule,value,callback)=>{
        /*console.log(rule);
        console.log(value);
        console.log(callback);*/
        if (!value){
          callback(new Error('请输入手机号'));
        }
        //使用正则表达式进行验证手机号码
        if (!/^1[3456789]\d{9}$/.test(value)){
          callback(new Error('手机号不正确'));
        }
        callback()
      }
      const state = reactive({
        circle: true,
        centerDialogVisible:false,
        labelPosition:'right',
        countdownSeconds:'',
        timer:null,
        width: 350,
        height: 40,
        bVerification:false,
        loginForm: {
            registerType:2,
            username:'',
            gender:'',
            QQNum:'',
            email:'',
            educational:'',
            cellPhone:'',
            system:'',
            major:'',
            class:'',
            verificationCode:'',
            password:'',
            againPassword:'',
            isPass: false,
            studentID:'',
            subject:''
        },
        rules:{
            registerType:[{ required: true, message: '请选择身份', trigger: 'change' }],
            username:[{ required: true, message: '请输入姓名', trigger: 'blur' }],
            gender:[{ required: true, message: '请选择性别', trigger: 'change' }],
            QQNum:[{ required: true, message: '请输入QQ', trigger: 'blur' }],
            email:[{ required: true, message: '请输入邮箱', trigger: 'blur' }],
            educational:[{ required: true, message: '请选择院校', trigger: 'change' }],
            studentID:[{ required: true, message: '请输入学号', trigger: 'blur' }],
            system:[{ required: true, message: '请选择系部', trigger: 'change' }],
            major:[{ required: true, message: '请选择专业', trigger: 'change' }],
            subject:[{ required: true, message: '请选择科目', trigger: 'change' }],
            class:[{ required: true, message: '请选择班级', trigger: 'change' }],
            cellPhone:[{ validator:validatePhone, trigger: 'blur' }],
            isPass:[{ validator:validatePass, trigger: 'blur' }],
            verificationCode:[{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
            password:[{ required: true, message: '请输入密码', trigger: 'blur' }, { min: 6, max: 16, message: '密码为6-16位', trigger: 'blur' },],
            againPassword:[{ required: true, message: '请再次输入密码', trigger: 'blur' }, { min: 6, max: 16, message: '密码为6-16位', trigger: 'blur' },{ validator:password, trigger: 'blur' }],
        },
        registerTypeOption:[
            {
                name:'教师',
                id:1
            },{
                name:'学生',
                id:2
            }
        ],
        genderOption:[
            {
                name:'男',
                id:1
            },{
                name:'女',
                id:2
            }
        ],
        educationalOption:[],
        systemOption:[],
        majorOptions:[],
        subjectOptions:[],
        classOptions:[],
        jueseOptions:[],
        countDown:'发送验证码',
        
        canNext:computed(()=>{
          return (state.loginForm.username != "" &&
          state.loginForm.password != "" &&
          state.isPass)
        })
      });
      // const setToken = ()=>{
      //     setStorage("ftoken", "ftoken");
      // }
      const methods = { 
         // 注册类型
         registerTypeChange(){

},
         // 获取学校下拉列表
    get_select_schoolList(){
        get_select_schoolList({}).then(res=>{
           if (res.data.code == 1) {
           state.educationalOption = res.data.data;
           }
        })
    },
     // 学校监听
     selectChange(event){
                state.loginForm.system = '';
                state.loginForm.major = '';
                methods.getF_pulldown_department();  
                },
       
        // 获取系
        getF_pulldown_department(){
          let params = {
            schoolId:state.loginForm.educational
          }
          get_select_departmentList(params).then(res=>{
            if (res.data.code == 1) {
                state.systemOption = res.data.data;
            }
        })
        },
        // 监听系
        systemChange(){
          state.loginForm.major = '';
                methods.get_select_majorList(); 
        },
        
            // 获取专业下拉列表
          get_select_majorList(){
              let params = {
                  departmentId:state.loginForm.system
              }
              get_select_majorList(params).then(res=>{
                  if (res.data.code == 1) {
                      state.majorOptions = res.data.data;
                  }
              })
          },
               // 专业监听
              majorChange(event){
                state.loginForm.class = '';
                methods.get_select_classNumList(); 


              },
               // 获取角色下拉列表
    get_select_role(){
        get_select_role().then(res=>{
            if (res.data.code == 1) {
                state.jueseOptions = res.data.data;
            }
        })
    },
              subjectChange(){

              },
              // 获取班级下拉
              get_select_classNumList(){
                let params = {
                  majorId:state.loginForm.major
                }
                get_select_classNumList(params).then(res=>{
                  if (res.data.code == 1) {
                    state.classOptions = res.data.data;
                  }
                })
              },
          // 发送验证码
          sendCode:() =>{
            let numCode = 0
              ruleFormRef.value.validateField(['cellPhone','isPass'],(valid) => {
                console.log(valid);
              if (valid) {
                numCode = 1;
                   console.log("未通过");
              } else {
                numCode = 0;
                console.log("通过");
             
              }
              
          });
          console.log(numCode);
           if (numCode == 0) {
                let params = {
                  telephone:state.loginForm.cellPhone,
                  sendType:1,
                }
                SendVerificationCode(params).then(res=>{
                  if (res.data.code == 1) {
                     ElMessage({
                    message: res.data.message,
                    type: "success",
                    showClose: true,
                  });
                   if (state.bVerification) return;
                  state.bVerification = true;
                   let countDown = 59;
                  const auth_time = setInterval(() => {
                  state.countDown = countDown--;
                  if (state.countDown <= 0) {
                      state.bVerification = false;
                      state.countDown = "发送验证码";
                      clearInterval(auth_time);
                  }
              }, 1000);
                  }else{
                    ElMessage({
                    message: res.data.message,
                    type: "error",
                    showClose: true,
                  });
                  }
                })
              
          }
          },
        passcallback:()=>{
          state.loginForm.isPass = true;
        },
        sibmitLogin: () => {
           
          // 通过ref的值触发验证
          ruleFormRef.value.validate((valid) => {
            console.log(valid);
              if (valid) {
                if (state.loginForm.registerType == 1) {
                   let params = {
                  realName:state.loginForm.username,
                  passWord:state.loginForm.againPassword,
                  sex:state.loginForm.gender,
                  phoneNum:state.loginForm.cellPhone,
                  qq:state.loginForm.QQNum,
                  email:state.loginForm.email,
                  schoolId:state.loginForm.educational,
                  departmentId:state.loginForm.system,
                  majorId:state.loginForm.major,
                  subjectId:'',
                  roleId:state.loginForm.juese,
                  verificationCode:state.loginForm.verificationCode
                }
                F_Register_teacherUser(params).then(res=>{
                  if (res.data.code == 1) {
                    state.centerDialogVisible = true;
                          const TIME_COUNT = 5;
                    if (!state.timer) {
                      state.countdownSeconds = TIME_COUNT;
                      state.timer = setInterval(() => {
                        if (state.countdownSeconds > 1 && state.countdownSeconds <= TIME_COUNT) {
                          state.countdownSeconds--;
                        } else {
                          clearInterval(state.timer); 
                          state.timer = null;
                          router.push("/loginPage");
                        }
                      }, 1000);
                    }
                  }else{
                    ElMessage({
                    message: res.data.message,
                    type: "error",
                    showClose: true,
                  });
                  }
                  
                })
                }else{
                  let params = {
                  realName:state.loginForm.username,
                  passWord:state.loginForm.againPassword,
                  sex:state.loginForm.gender,
                  phoneNum:state.loginForm.cellPhone,
                  studentNum:state.loginForm.studentID,
                  qq:state.loginForm.QQNum,
                  email:state.loginForm.email,
                  schoolId:state.loginForm.educational,
                  departmentId:state.loginForm.system,
                  majorId:state.loginForm.major,
                  classId:state.loginForm.class,
                  verificationCode:state.loginForm.verificationCode
                }
                F_Register_studentUser(params).then(res=>{
                  if (res.data.code == 1) {
                    state.centerDialogVisible = true;
                          const TIME_COUNT = 5;
                    if (!state.timer) {
                      state.countdownSeconds = TIME_COUNT;
                      state.timer = setInterval(() => {
                        if (state.countdownSeconds > 1 && state.countdownSeconds <= TIME_COUNT) {
                          state.countdownSeconds--;
                        } else {
                          clearInterval(state.timer); 
                          state.timer = null;
                          router.push("/loginPage");
                        }
                      }, 1000);
                    }
                  }else{
                    ElMessage({
                    message: res.data.message,
                    type: "error",
                    showClose: true,
                  });
                  }
                  
                })
                }
               
              } else {
              console.log("未通过");
              }
          });
        },
          gologin:() =>{
           router.push("/loginPage");
        },
      };
      onMounted(() => {
          store.commit("SetnarbarMenuActive", 1);
          setStorage("narbarMenuActive", 1);
          methods.getF_pulldown_department();
          methods.get_select_schoolList();
          methods.getF_pulldown_department();
          methods.get_select_majorList();
          methods.get_select_role();
          methods.get_select_classNumList();
      });
  
      return {
        ...toRefs(state),
        ...methods,
        ruleFormRef,
      };
    },
    components:{
      dragVerify
    }
  });
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  .lg-btn {
    width: 365px;
    height: 42px;
    margin: 40px 0 0 0;
    position: relative;
  }
  .el-button {
      margin-left: 10px;
      border-radius: 5px;
      background: #76C8F6;
      color: #fff;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
  }
  .projectName {
    padding: 0px 0 20px 50px;
    position: relative;
    height: 48px;
    font-size: 30px;
    font-weight: 600;
    color: #333333;
    // line-height: 48px;
    text-align: center;
  }
  .loginPageBox{
    width: 100%;
    height: 100%;
    background-color: #F7F8FA;
  }
  #loginPage {
   position: absolute;
    top: 50%;
    left: 50%;
    width:85%;
    height:90%;
    padding:20px;
    text-align:center;
    transform: translate(-50%, -50%);
    .img-c{
      width:100%;
      height:100%;
      position:absolute;
      top:0;
      left:0;
      overflow-y: hidden;
      img{
        top:0;
        left:0;
        width:100%;
        height:100%;
        // object-fit: cover;
      }
    }
  
    .login_box {
    //   width: 568px;
      height:95%;
      position:absolute;
      top:2%;
      right:5%;
    //   transform: translate(0,-50%);
    //   background: #fff;
      overflow-y: auto;
    }
  
    .lp-c {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 99;
    }
  
    .lp-cont {
      position: relative;
      padding: 0 60px;
    }
    .lg-logo {
      position: absolute;
      top: -15%;
      left: 50%;
      transform: translate(-50%, -15%);
    }
    .lg-from {
      display: flex;
      justify-content: center;
    }
    .lg-from img {
      position: absolute;
      top: 11px;
      left: 20px;
      width: 22px;
      height: 22px;
      object-fit: contain;
    }
  
    .el-form-item {
      margin-bottom: 20px;
      &.codeItem {
        width: 365px;
        position: relative;
        height: 40px;
      }
      &.spwd {
        margin: 10px 0 15px;
      }
    }
    /* 验证码 */
    .codeItem {
      position: relative;
    }
    .emCode {
      box-sizing: border-box;
      position: absolute;
      top: 11px;
      left: 80%;
      margin: 0 0 0 -132px;
      width: 106px;
      height: 37px;
      box-sizing: border-box;
      background: #0a2138;
      cursor: pointer;
      .s-canvas {
        height: 100%;
        canvas {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }
    }
    /* 登录按钮 */
    .loginBtn {
      width: 370px;
      cursor: pointer;
      font-size: 15px;
      color: #fff;
      line-height: 42px;
      text-align: center;
      background: #76C8F6;
      color: #fff;
      border: none;
      border-radius: 2px;
      &.gray {
        background: #666;
        color: #cecece;
        cursor: default;
      }
    }
    :deep(.login_box){
      .el-input{
        // background: #fff;
      }
      .el-checkbox__inner {
        border: 1px solid #6fe9ef;
        background-color: transparent;
      }
      .el-input--prefix {
      }
      .el-input--prefix .el-input__inner {
        padding-left: 70px;
        background-size: 100% 100%;
        height: 50px;
        border: none;
        margin: -10px 0;
        width: 410px;
        opacity: 0.6;
        &:hover,
        &:focus {
          opacity: 1;
        }
      }
      span.el-input__prefix {
        transform: translate(0, -50%);
        width: 25px;
        height: 25px;
        left: 35px;
        top: 50%;
      }
      .el-form-item__content {
        display: flex;
        justify-content: center;
        width: 100%;
        line-height: 60px;
      }
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #16d0ff;
      }
      .el-input__inner {
        color: #666;
        background: #fff;
      //   padding: 0 50px;
        border-radius: 2px;
        border: 1px solid #E1E1E1;
        border-radius: 50px;
      }
  
    }
  }
  div::-webkit-scrollbar {
    width: 4px;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    opacity: 0.2;
    background: #76C8F6;
  }
  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: #fff;
  }
  </style>